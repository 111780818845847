<template>
  <div>
    <v-card-text class="">
      <v-card-text>

        <v-form
            ref="form"
            @submit.prevent="validate"
        >
          <v-row>
            <v-col cols="12" v-if="distanceMatrix.distance && distanceMatrix.duration">
              <template>
                <v-toolbar
                    dark
                    color="teal"
                >
                  <v-toolbar-title v-if="distanceMatrix.distance">Distance Estimée : {{distanceMatrix.distance.text}},  </v-toolbar-title>
                  <v-toolbar-title v-if="distanceMatrix.duration">Temps Estimée : {{distanceMatrix.duration.text}} </v-toolbar-title>
                </v-toolbar>
              </template>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-card-title class="text-sm">Point d'origine</v-card-title>
              <v-col lg="12">
                <v-autocomplete
                    v-model="originSelect"
                    :loading="loadingOrigin"
                    :items="itemsSearchOrigin"
                    :search-input.sync="searchOrigin"
                    item-text="name"
                    item-value="geometry"
                    cache-items
                    class="mx-4"
                    hide-details
                    chips
                    clearable
                    hide-selected
                    label="Origine"
                    solo
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-card-title class="text-sm">Point de Destination</v-card-title>
              <v-col lg="12">
                <v-autocomplete
                    v-model="destinationSelect"
                    :loading="loadingDestination"
                    :items="itemsSearchDestination"
                    :search-input.sync="searchDestination"
                    item-text="name"
                    item-value="geometry"
                    cache-items
                    class="mx-4"
                    hide-details
                    chips
                    clearable
                    hide-selected
                    label="Destination"
                    solo
                ></v-autocomplete>
              </v-col>
            </v-col>
          </v-row>

          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <p class="text--primary mt-n3 mb-2">
                  Option de livraison
                </p>
                <v-radio-group
                    @change="loadTarif"
                    v-model="editedItem['tree_item\\deliverymode']"
                    row
                    class="mt-0"
                >
                  <v-radio
                      v-for="tt in deliverymodes"
                      :key="tt.name"
                      :label="tt.name"
                      :value="tt.name"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-btn
                    v-if="loadtransporter"
                    :loading="loadtransporter"
                    :disabled="loadtransporter"
                    color="blue-grey"
                    class="ma-2 white--text"
                    fab
                >
                </v-btn>
                <v-expansion-panels v-else>
                  <v-expansion-panel
                      v-if="tarifs"
                      v-model="panel"
                      key="location"
                  >
                    <v-expansion-panel-header>
                      Selectionner le transporter
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list-item-group
                          active-class=""
                      >
                        <v-radio-group v-model="editedItem['tarif']">
                          <v-list-item v-for="item in tarifs" :key="item.id">
                            <template v-slot:default="{  }">
                              <v-list-item-action>
                                <v-radio
                                    :key="item.id"
                                    :value="item">
                                </v-radio>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>{{ item.cost }}XAF</v-list-item-title>
                                <v-list-item-subtitle>{{ item.transporter.name }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-radio-group>
                      </v-list-item-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </div>

          <v-btn
              block
              color="success"
              class="mt-6 w-50"
              small
              :loading="loading"
              type="submit"
          >
            suivant
          </v-btn>

        </v-form>

      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
import {Drequest} from "../../plugins/Drequest";

export default {
  name: "Destination",
  props: ['nextStep'],

  data: () => ({
    panel: false,
    loadtransporter: false,
    loading: false,
    searchDestination: null,
    searchOrigin: null,
    inputValueOne: false,
    loadingOrigin:false,
    loadingDestination: false,
    originSelect: null,
    destinationSelect: null,
    itemsSearchOrigin: [],
    itemsSearchDestination: [],
    distanceMatrix: {},
    tarifs: [],
    editedItem:{},
    departureItem: {},
    arrivalItem: {},
    deliverymodes: [],
  }),
  watch: {
    searchOrigin (val) {
      this.loadingOrigin = true;
      val && val !== this.select && setTimeout(() => {
        //console.log(this.getPlaceFrom(val));
        this.itemsSearchOrigin = this.getPlaceFrom(val, 'labelTypeOne');
      }, 500)
      this.loadingOrigin = false;
    },
    searchDestination (val) {
      this.loadingOrigin = true;
      val && val !== this.select && setTimeout(() => {
        //console.log(this.getPlaceFrom(val));
        this.getPlaceFrom(val, 'labelTypeTwo');
      }, 500)
      this.loadingOrigin = false;
    }
  },beforeMount() {
    this.loadDelivery();
  },
  methods: {
    loadDelivery: function () {
      Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=deliverymode')
          .get((response) => {
            this.deliverymodes = response.listEntity;
          });
    },
    async loadTarif(){
      this.loadtransporter = true;
      await Drequest
          .api("lazyloading.tarrif?dfilters=on&tarrif.tarifftype:eq=k")
          .get((response) => {
            if(response.success){
              this.getDistanceMatrix({lat:this.originSelect.coords.lat, lng:this.originSelect.coords.lng}, {lat:this.destinationSelect.coords.lat, lng:this.destinationSelect.coords.lng}, response.listEntity);
              this.loadtransporter = false;
              this.panel = true;
            }
          });
    },
    getPlaceFrom(place, labelType){
      let results = [];
      Drequest.init("https://maps.googleapis.com/maps/api/place/textsearch/json?query="+place+"&fields=formatted_address,name&key=AIzaSyBdHtCszsxBCg5EZnF6uXNuucr--mHoelg")
          .get((response) => {
            if(response.status && response.status == 'OK'){
              response.results.forEach((value) => {
                results.push({name:value.name, geometry: {coords:value.geometry.location, label: value.name}});
                //console.log(value);
              });
              if(labelType == 'labelTypeOne'){
                this.itemsSearchOrigin = results;
              }else{
                this.itemsSearchDestination = results;
              }
              return results;
            }else{
              alert('erreur');
            }
          })
    },
    getDistanceMatrix(origin, destination, data){
      let transporters = [];
      Drequest.init("https://maps.googleapis.com/maps/api/distancematrix/json?units=kilometer&origins="+origin.lat+","+origin.lng+"&destinations="+destination.lat+","+destination.lng+"&key=AIzaSyBdHtCszsxBCg5EZnF6uXNuucr--mHoelg")
          .get((response) => {
            let dist = response.rows[0].elements[0];
            let distanceMatrix = (dist.distance.value/1000);
            data.forEach(item => {
              if(item.max > distanceMatrix && item.min < distanceMatrix){
                transporters.push(item);
              }
            })
            this.distanceMatrix = dist;
            this.tarifs = transporters;
          })
    },
    validate(){
      let data = {
        //country: this.departureItem.countryiso,
        //shop_country: this.departureItem.countryiso,
        //destination_country: this.arrivalItem.countryiso,
        kilometer: (this.distanceMatrix.distance.value/1000),
        destination: this.destinationSelect.label,
        departure: this.originSelect.label,
        deliveryoption: this.editedItem["tree_item\\deliverymode"],
        tarrif_ref: this.editedItem["tarif"]
      }
      //console.log(data)
      this.$store.commit('save_customer_location', data);
      this.nextStep(1);
    }
  }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.auth-wrapper.auth-v1 .auth-inner {
  width: 50rem !important;
  z-index: 1;
}
@media (max-width: 360px) {
  .v-stepper__content {
    top: 0;
    padding: 0px !important;
    flex: 1 0 auto;
    width: 100%;
  }
  .v-card__subtitle, .v-card__text, .v-card__title {
    padding: 0px !important;
  }
}

</style>